import React from "react"
import { Link } from "gatsby"
import "./scholarshipContent.scss"

const ScholarshipContent = () => {
  return (
    <div className="section-container scholarship-intro">
      <div className="container">
        <div className="main-section-heading">
          <h3 className="text-center">Part-Scholarship</h3>
        </div>
        <p>Part-scholarships at Codetrain are given for three reasons.</p>

        <div className="row">
          <div className="col-md-6 col-xs-12">
            <ul>
              <li>
                <h6>To promote diversity and inclusion</h6>
                <p>
                  By giving part-scholarships to underrepresented groups in
                  tech, we get to include people who would have otherwise been
                  excluded by no fault of theirs. We get to rope in more women,
                  disabled people,low-income earners, refugees, and rural
                  dwellers.
                </p>
              </li>
              <li>
                <h6>To encourage innovation and brilliance</h6>
                <p>
                  If you have an extraordinary achievement or have proven
                  yourself to be very good at something, we’re looking for you.
                  Any kind of achievement took you a lot of grit and boldness.
                  And we’d be happy to support your Codetrain journey.
                </p>
              </li>
            </ul>
          </div>

          <div className="col-md-6 col-xs-12">
            <ul>
              <li>
                <h6>To promote social impact</h6>
                <p>
                  At Codetrain, we believe in making other people’s lives
                  better. If you have done significant work in your community
                  through service or leadership, you have a high chance of
                  securing a part-scholarship.
                </p>

                <p>
                  If you fit the bill for one or more of these categories,
                  you’re likely to receive a part-scholarship.
                </p>
              </li>
            </ul>
          </div>
        </div>

        <div className="row enroll-section">
          <Link
            to="https://app.codetrain.africa/apply"
            className="btn-custom-primary-large mx-auto"
          >
            Enroll now
          </Link>
        </div>
      </div>
    </div>
  )
}

export default ScholarshipContent
