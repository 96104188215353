import React from "react"
import { Meta, Title } from "react-head"
import MainLayout from "../components/MainLayout"
import ScholarshipContent from "../components/scholarship-content/ScholarshipContent"

const Scholarship = () => {
  return (
    <MainLayout>
      <Title>Codetrain Africa | Scholarship</Title>
      <Meta
        name="description"
        content="Scholarships at Codetrain are given for three reasons."
      />
      <ScholarshipContent />
    </MainLayout>
  )
}

export default Scholarship
